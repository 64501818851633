<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p>{{addFlag?"添加门店":"编辑门店"}}</p>
      <el-form class="mt-24" label-width="100px" :model="store_form" :rules="rules" ref="store_form">
        <el-form-item label="门店名称" prop="storeName">
          <el-input class="form-input" placeholder="请输入门店名称" clearable v-model="store_form.storeName"></el-input>
        </el-form-item>
        <el-form-item label="门店电话" prop="storeTelephone">
          <el-input class="form-input" placeholder="手机格式：13912345678 固话格式：0755-1234567或010-12345678" :maxlength="12" clearable v-model="store_form.storeTelephone"></el-input>
        </el-form-item>
        <el-form-item label="详细位置" prop="address">
          <el-input class="form-input" placeholder="输入详细位置" clearable v-model="store_form.address" @keyup.enter.native="codeAddress"></el-input>
          <el-button class="form-button-w70 ml-15" type="primary" @click="codeAddress">搜索位置</el-button>
        </el-form-item>
        <el-form-item>
          <div id="mapContainer" class="map" style="width:100%; height:550px"></div>
        </el-form-item>
        <el-form-item label="门店主图" prop="storeCover">
          <UploadPics :uploadType="'main'" :imgLimit="1" :imgSize="'160*120'" :imgsPreview="mainImgStr" @getImgData="getImgData"/>
        </el-form-item>
        <el-form-item label="门店辅图" prop="storeImg">
          <UploadPics :uploadType="'pics'" :imgLimit="6" :imgSize="'160*120'" :imgsPreview="subImgStr" @getImgData="getImgData"/>
        </el-form-item>
        <!-- <el-form-item label="门店楼层" prop="base_price">
          <el-input
            class="form-input-200"
            placeholder="请输入楼层名称"
            v-model="store_form.base_price"
          ></el-input>
          <el-button class="form-button" type="primary" @click="addTag">添加</el-button>
          <div style="height:150px;">
            <el-tag v-for="tag in dynamicTags" :key="tag" closable>
              {{ tag }}
            </el-tag>
          </div>
        </el-form-item>-->
        <el-form-item label="门店楼层" prop="floorInfoList">
          <el-tag
            :key="floor.floorName"
            v-for="floor in store_form.floorInfoList"
            closable
            :disable-transitions="false"
            @close="handleClose(floor.floorName)"
          >{{ floor.floorName }}</el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          ></el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加楼层名称</el-button>
        </el-form-item>
        <el-form-item label="停车场" prop="carPark">
          <el-select v-model="store_form.carPark" placeholder="请选择停车场">
            <el-option
              v-for="item in parkList"
              :key="item.parkingCode"
              :label="item.parkingName"
              :value="item.parkingCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-row class='label'>自提地址设置</el-row>
        <el-form-item label="地址" prop="storeAddress">
          <el-input class="form-input" placeholder="输入位置" clearable v-model="store_form.storeAddress"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="storePhone">
          <el-input class="form-input" placeholder="输入电话" clearable v-model="store_form.storePhone"></el-input>
        </el-form-item>
        <el-form-item label="门店描述">
          <tinymce v-model="store_form.remark" :height="300" />
        </el-form-item>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="$router.go(-1)">取消</el-button>
      <el-button class="form-button" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics"
import { getparkList, subStoreForm, getStoreDetail} from "@/api/service_center/stores";
const maps = require("qqmap");

export default {
  components: {
    Tinymce,
    UploadPics
  },
  data() {
    return {
      store_form: {
        storeName: "",
        locationX: "",
        locationY: "",
        carPark: "",
        parkName: "",
        storeAddress: "",
        storePhone: "",
        remark: "",
        storeTelephone: "",
        storeCover: "",
        storeImg: "",
        businessHours: "",
        address: "",
        floorInfoList: [],
      },
      parkList: [],
      map: null,
      geocoder: null,
      marker: null,
      rules: {
        storeName: [{ required: true, message: "门店名称不能为空", trigger: 'blur'}],
        status: [{ required: true }],
        remark: [{ required: true }],
        storeTelephone: [
          { required: true, message: "门店电话不能为空", trigger: 'change'}, 
          { pattern: /^(([1]([3-9])[0-9]{9})|([0]\d{2}-\d{8})|([0]\d{3}-\d{7}))$/, message: "号码格式有误", trigger: 'change'}
        ],
        storeCover: [{ required: true, message: "门店主图不能为空" }],
        storeImg: [{ required: true, message: "门店辅图不能为空" }],
        address: [{ required: true, message: "门店地址不能为空", trigger: 'blur'}],
        floorInfoList: [{ required: true, message: "门店楼层不能为空",}],
        carPark:[{ required: true, message: "停车不能为空", trigger: 'change'}],
        storeAddress: [{ required: true ,message: "自提地址不能为空", trigger: 'blur'}],
        storePhone: [
          { required: true, message: "自提号码不能为空", trigger: 'change'}, 
          { pattern: /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/, message: "号码格式有误", trigger: 'change'}
        ],
      },
      inputVisible: false,
      inputValue: "",
      addFlag: true,
      storeId: "",
      marker: null,
      mainImgStr: "",
      subImgStr: "",
    };
  },
  mounted() {
    this.handleOpeType();
    this.initMap();
    this.getParkData();
    // console.log("params",this.$route.params.storeId);
  },
  methods: {
    //判断操作类型-新增/编辑
    handleOpeType() {
      let params = this.$route.params;
      this.addFlag =
        params.addFlag != undefined
          ? params.addFlag
          : sessionStorage.getItem("ADD_FLAG");
      if (params.addFlag != undefined)
        sessionStorage.setItem("ADD_FLAG", params.addFlag);
      if (params.storeId) sessionStorage.setItem("STORE_ID", params.storeId);
      if (String(this.addFlag) == "false") {
        this.storeId = params.storeId || sessionStorage.getItem("STORE_ID");
        this.getStoreData(this.storeId);
      }
    },
    //获取停车列表
    getParkData() {
      getparkList().then(res => {
        if (res.data.body) {
          this.parkList = res.data.body
        }
      })
    },
    //编辑  获取门店信息
    getStoreData(id) {
      getStoreDetail(id).then(res => {
        if (res.data.body) {
          this.store_form = res.data.body.shopStore;
          this.mainImgStr = this.store_form.storeCover
          this.subImgStr = this.store_form.storeImg
          this.initMap();
        }
      })
    },
    //初始化地图
    initMap() {
      const vm = this;
      vm.map = maps.init("ZC3BZ-7YXCK-LOYJY-A57JR-2HYR2-NDBZV", () => {
        this.map = new qq.maps.Map(document.getElementById("mapContainer"), {
          zoom: 13,
          center: new qq.maps.LatLng(
            vm.store_form.locationX,
            vm.store_form.locationY
          ),
          mapTypeId: qq.maps.MapTypeId.ROADMAP
        });
        //获取城市列表接口设置中心点
        let citylocation = new qq.maps.CityService({
            complete : function(result){
              vm.map.setCenter(result.detail.latLng);
            }
        });
        //根据用户IP查询城市信息
        if(!vm.store_form.locationX || !vm.store_form.locationY) citylocation.searchLocalCity();

        //点击Marker会弹出反查结果
        let info = new qq.maps.InfoWindow({
            map: this.map
        });

        //添加点击事件
        qq.maps.event.addListener(this.map, "click", function(event) {
          // console.log('click', event);
          let latLng = event.latLng,
            lat = latLng.getLat().toFixed(10),
            lng = latLng.getLng().toFixed(10);
          vm.store_form.locationX = lat;
          vm.store_form.locationY = lng;
          let latLng2 = new qq.maps.LatLng(lat, lng);
          vm.geocoder.getAddress(latLng2);
          vm.setMarker(latLng);
        });
        //调用地址解析类
        this.geocoder = new qq.maps.Geocoder({
          complete: function(result) {
            // console.log('result',result);
            vm.store_form.locationX = result.detail.location.lat;
            vm.store_form.locationY = result.detail.location.lng;
            // vm.store_form.address  = result.detail.address 
            // info.open();
            // info.setContent('<div style="width:200px;height:100px;">' +
            //     result.detail.address + '</div>');
            // info.setPosition(result.detail.location);
            // + (result.detail.addressComponents.town || result.detail.addressComponents.village) + result.detail.addressComponents.street + result.detail.addressComponents.streetNumber
            vm.map.setCenter(result.detail.location);
            vm.setMarker(result.detail.location);
            // console.log(result.detail.address)
            vm.store_form.address = result.detail.address;
          }
        });
        this.marker = new qq.maps.Marker({
            map: this.map,
            position: new qq.maps.LatLng(
              this.store_form.locationX,
              this.store_form.locationY
            )
          });
      });
    },
    //解析地址
    codeAddress() {
      this.geocoder.getLocation(this.store_form.address);
    },
    setMarker(location) {
      const vm = this;
      if (vm.marker) {
        vm.marker.setPosition(location);
      } else {
        vm.marker = new qq.maps.Marker({
          map: vm.map,
          position: location
        });
      }
    },
    getImgData(imgData, type) {
      if(type == 'pics') this.store_form.storeImg = imgData.subImgs
      if(type == 'main') this.store_form.storeCover = imgData.mainImg
    },
    //tag操作
    handleClose(tag) {
      let idx = this.store_form.floorInfoList.filter(
        item => item.floorName == tag
      );
      this.store_form.floorInfoList.splice(idx, 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.store_form.floorInfoList.push({ floorName: inputValue });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    getContent(content) {
      this.store_form.remark = content
    },
    submit() {
      this.$refs['store_form'].validate(valid => {
        if(!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: 'warning'
          })
          return
        }
        this.submitForm()
      })
    },
    submitForm() {
      this.store_form.parkName = this.parkList.find((ele) => ele.parkingCode == this.store_form.carPark).parkingName
      let data = this.store_form;
      if (String(this.addFlag) == "false") data.id = this.storeId;
      // // console.log(data)
      subStoreForm(data, String(this.addFlag) == "true" ? 0: 1).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.$router.go(-1)
      }) 
    }
  }
};
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.label{
  width: 100px;
  margin-bottom: 22px;
  color: #606266;
  line-height: 40px;
  font-weight: 700;
}
</style>
